@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

span {
  font-size: calc(0.8rem + 0.6vw);
}

.marquee-wrapper {
  position: relative;
  width: 100%;
  max-width: 90vw;
  overflow: hidden;
}

.marquee-wrapper::before,
.marquee-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px; /* Adjust the width of the fade */
  z-index: 2;
}

.marquee-wrapper::before {
  left: 0;
  background: linear-gradient(to right, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
}

.marquee-wrapper::after {
  right: 0;
  background: linear-gradient(to left, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
}

@font-face {
  font-family: "Jost-Bold";
  src: url("../src/assets/fonts/Jost-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Jost-Regular";
  src: url("../src/assets/fonts/Jost-Regular.ttf") format("truetype");
}

.jost {
  font-family: "Jost-Regular";
}

.jost-bold {
  font-family: "Jost-Bold";
}

body {
  margin: 0;
  font-family: "Jost-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

img {
  max-width: 35vh;
  height: auto;
  margin: 0px 25px;
}

/* Add new class for the TARANTULA logo */
.tarantula-logo {
  max-width: none !important;
  margin: 0 !important;
}

.logo-container {
  width: calc(60vw + 100px);
  max-width: 841px;
  margin-left: 5vw;
}

.welcome-background {
  background-color: #f6f6f6;
}

.top-spacer {
  height: 20px;
}

@media (min-width: 640px) {
  .top-spacer {
    height: 100px;
  }
}

p {
  font-size: calc(0.8rem + 0.6vw);
  line-height: 1.5;
  margin: 0;
}

@media (max-width: 768px) {
  .welcome-container {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  p {
    font-size: calc(0.7rem + 0.6vw);
  }
}

.welcome-container
// .marquee-wrapper::before,
// .marquee-wrapper::after 
{
  background-color: #f6f6f6;
}

.searching-dots::after {
  content: '';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% { content: ''; }
  40% { content: '.'; }
  60% { content: '..'; }
  80%, 100% { content: '...'; }
}

.welcome-container {
  opacity: 0;
  visibility: hidden;
}

.welcome-container.mounted {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}

